import React, { useEffect, useState } from 'react';
import './App.css';

const API_BASE_URL = process.env.REACT_APP_API_URL;
const itemsPerPage = 50;
const DataTable = ({ data, onRowClick, loadingOrderId, handleFileChange, uploadedImageUrl, handleInputChange, handleSave, currentPage, savingStatus, setSavingStatus, areAllFieldsFilled, selectedRows, setSelectedRows }) => {

  // Function to handle individual row checkbox changes
  const handleRowSelectionChange = (orderId, isChecked) => {
    const newSelectedRows = isChecked ? [...selectedRows, orderId] : selectedRows.filter(id => id !== orderId);
    setSelectedRows(newSelectedRows);
  };

  // Function to handle "Select All" checkbox changes
  const handleSelectAllChange = (isChecked) => {
    if (isChecked) {
      const allOrderIds = data.map(item => {
        const parts = item.node.id.split('/');
        return parts[parts.length - 1];
      });
      setSelectedRows(allOrderIds);
    } else {
      setSelectedRows([]);
    }
  };

  // Check if all rows are selected
  const isAllSelected = data.length === selectedRows.length;

  return (
    <table className='mainTable'>
      <thead>
        <tr>
          <th><input type="checkbox" checked={isAllSelected} onChange={(e) => handleSelectAllChange(e.target.checked)} /></th>
          <th>S.No.</th>
          <th>Order Date</th>
          <th>Order ID</th>
          <th>Customer Image</th>
          <th>Customer Name</th>
          <th>Customer location</th>
          <th>Date of Service</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => {
          const serialNumber = (currentPage - 1) * itemsPerPage + index + 1;
          const parts = item.node.id.split('/');
          const oId = parts[parts.length - 1];
          const isSelected = selectedRows.includes(oId);
          return (
            <tr key={item.node.id} data-order-id={oId}
            // onClick={() => onRowClick(oId)}
            >
              <td><input type="checkbox" checked={isSelected} onChange={(e) => handleRowSelectionChange(oId, e.target.checked)} /></td>
              <td>{serialNumber}</td>
              <td data-id={item.node.createdAt}>{item.node.createdAt.split('T')[0]}</td>
              <td>{item.node.name}</td>
              <td>
                {/* {uploadedImageUrl[oId] && <img src={uploadedImageUrl[oId].replace(/(\.[\w\d_-]+)$/i, '_50x$1')} width="50px" alt="Uploaded" />}  */}
                {/* {!uploadedImageUrl[oId] && ( */}
                <input
                  type="file"
                  id={`customer_image_${oId}`}
                  name={`customer_image_${oId}`}
                  accept="image/png, image/jpeg"
                  data-value={uploadedImageUrl[oId] || ''}
                  onChange={(event) => handleFileChange(event, oId)} />
                {/* )} */}
              </td>
              <td>
                <input
                  type="text"
                  id={`customer_name_${oId}`}
                  name={`customer_name_${oId}`}
                  onChange={(event) => handleInputChange(oId, 'name', event.target.value)}
                />
              </td>
              <td>
                <textarea
                  id={`customer_location_${oId}`}
                  name={`customer_location_${oId}`}
                  rows="3"
                  onChange={(event) => handleInputChange(oId, 'location', event.target.value)}
                ></textarea>
              </td>
              <td>
                <input
                  type="date"
                  id={`customer_date_${oId}`}
                  name={`customer_date_${oId}`}
                  onChange={(event) => handleInputChange(oId, 'date', event.target.value)}
                />
              </td>
              <td>
                <button
                  className='saveBtn'
                  onClick={() => handleSave(oId)}
                  disabled={savingStatus[oId] === 'saving' || !areAllFieldsFilled(oId)}
                >
                  {savingStatus[oId] === 'saving' ? 'Saving...' :
                    savingStatus[oId] === 'saved' ? 'Saved' : 'Save'}
                </button>
              </td>
              {loadingOrderId === oId && <td><div className="data-loader"><div><div></div><div></div><div></div><div></div></div></div></td>}
            </tr>
          )
        })}

      </tbody>
    </table>
  );
};


const getFormattedDate = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}/${month}/${day}`;
};

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(yesterday.getDate() - 1);

const last7Days = new Date(today);
last7Days.setDate(last7Days.getDate() - 7);

const last15Days = new Date(today);
last15Days.setDate(last15Days.getDate() - 15);

const todayValue = getFormattedDate(today);
const yesterdayValue = getFormattedDate(yesterday);
const last7DaysValue = getFormattedDate(last7Days);
const last15DaysValue = getFormattedDate(last15Days);

function PasswordForm({ setToken }) {
  const [password, setPassword] = useState('');

  const handleSubmit = async e => {
    e.preventDefault();
    const response = await fetch(`${API_BASE_URL}/verifyPassword`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ password })
    });
    if (response.ok) {
      const data = await response.json();
      setToken(data.token);
    } else {
      // Handle invalid password
    }
  };

  return (
    <form className="passwordForm" onSubmit={handleSubmit}>
      <div className="passwordWrapper">
        <img src="https://www.drsheths.com/cdn/shop/files/width-1000px_150x.png" alt="Dr Sheths" />
        <h3>ENTER PASSWORD</h3>
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        <button className="submitPassword" type="submit">Submit</button>
      </div>
    </form>
  );
}

// Modal component

const Modal = ({ isOpen, onClose, selectedOrders, onSaveBulkEdit, bulkEditData, setBulkEditData }) => {
  const [saveButtonText, setSaveButtonText] = useState('Save');
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    // Form validation check
    const isFilled = bulkEditData.customerName && bulkEditData.customerLocation && bulkEditData.dateOfService && bulkEditData.customerImage;
    setIsFormValid(!!isFilled);
  }, [bulkEditData]);


  if (!isOpen) return null;

  // Updated handleInputChange to only deal with text and date inputs
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBulkEditData({ ...bulkEditData, [name]: value });
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch('https://app.meroxio.com/shopify-app-apis/uploadImage', {
        method: 'POST',
        body: formData,
      });
      if (!response.ok) throw new Error('Upload failed');
      const result = await response.json();
      setBulkEditData(prevData => ({
        ...prevData,
        customerImage: result.finalImageUrl
      }));
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };
  const handleSaveButtonClick = async () => {
    setSaveButtonText('Saving...');
    try {
      await onSaveBulkEdit(bulkEditData);
      setSaveButtonText('Saved');
    } catch (error) {
      console.error('Error saving data:', error);
      setSaveButtonText('Error');
    }
  };
  return (
    <div className="modalBackdrop" onClick={onClose}>
      <div className="modalContent" onClick={(e) => e.stopPropagation()}>
        <div className="modalHeader">
          <h2>Bulk Edit</h2>
          <button className="closeBtn" onClick={onClose}><svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px"><path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"></path></svg></button>
        </div>
        <div className="modalBody">
          <div className="customerDetailsWrapper">
            <div>
              <label>Customer Image</label>
              <input
                type="file"
                name="customerImage"
                accept="image/png, image/jpeg"
                onChange={handleFileUpload} />
            </div>
            <div>
              <label>Customer Name</label>
              <input
                type="text"
                name="customerName"
                value={bulkEditData.customerName || ''}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Customer Location</label>
              <textarea
                name="customerLocation"
                value={bulkEditData.customerLocation || ''}
                onChange={handleInputChange}
              ></textarea>
            </div>
            <div>
              <label>Date of Service</label>
              <input
                type="date"
                name="dateOfService"
                value={bulkEditData.dateOfService || ''}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <button
           className="saveBulkData"
           onClick={handleSaveButtonClick}
           disabled={!isFormValid}
           
          >
            {saveButtonText}
          </button>

          <h4>Selected Orders: </h4>
          <ul>
            {selectedOrders.map((order, index) => (
              <li key={index}>{order?.node?.name}</li> // Correctly using optional chaining
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

function App() {
  const [selectedRows, setSelectedRows] = useState([]);
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orders, setOrders] = useState([]);
  const [metafields, setMetafields] = useState(null);
  const [loadingOrderId, setLoadingOrderId] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState({});
  const [rowData, setRowData] = useState({});
  const [cursor, setCursor] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [cursorHistory, setCursorHistory] = useState(['']); // Initialize with one entry
  const [savingStatus, setSavingStatus] = useState({});
  const [selectedFilter, setSelectedFilter] = useState(todayValue);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [bulkEditData, setBulkEditData] = useState({
    customerName: '',
    customerLocation: '',
    dateOfService: '',
    customerImage: ''
  });

  // Function to open modal
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const refreshPage = () => {
    const url = new URL(window.location.href);
    url.searchParams.set('reloadTime', Date.now().toString());
    url.searchParams.set('cacheBuster', Math.random().toString());
    window.location.href = url.toString();
  };  

  // Function to close modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const onSaveBulkEdit = async (bulkEditData) => {
    const orderIds = selectedRows;
    const metafields = [
      { key: "name", value: bulkEditData.customerName },
      { key: "location", value: bulkEditData.customerLocation },
      { key: "date", value: bulkEditData.dateOfService },
      { key: "image", value: bulkEditData.customerImage },
    ];
    console.log(metafields);
    // Call your backend API
    try {
      const response = await fetch(`${API_BASE_URL}/bulkSaveMetafield`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token, // Ensure you have the token available
        },
        body: JSON.stringify({ orderIds, metafields })
      });

      if (!response.ok) {
        throw new Error('Failed to save bulk edits');
      }
      setIsModalOpen(false);
      const result = await response.json();
      console.log(result.message); // Handle success
    } catch (error) {
      console.error('Error in bulk saving metafields:', error);
    }
  };


  // Prepare selected orders for display in the modal
  const selectedOrders = selectedRows.map(orderId =>
    orders.find(order => order.node.id.split('/').pop() === orderId)
  ).filter(order => order !== undefined);

  // Define fetchOrders without useEffect
  const fetchOrders = async (cursor, newPage, filter = todayValue) => {
    try {
      setLoading(true);
      const response = await fetch(`${API_BASE_URL}/fetchOrders?cursor=${cursor}&filter=created_at:>${filter}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
      });
      if (response.status === 403) {
        localStorage.removeItem('token'); // Remove the token
        window.location.reload(); // Reload or redirect to login
        return;
      }
      const data = await response.json();
      setOrders(data.edges);
      setCursorHistory((prev) => [...prev, data.pageInfo.hasNextPage ? data.edges[data.edges.length - 1].cursor : '']);
      setCurrentPage(newPage);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Initial load
  useEffect(() => {
    if (token) {
      fetchOrders('', 1, todayValue);
    }
  }, [token]);

  const handleNextPage = () => {
    const nextCursor = cursorHistory[cursorHistory.length - 1];
    if (nextCursor) {
      fetchOrders(nextCursor, currentPage + 1, selectedFilter);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      const prevCursor = cursorHistory[cursorHistory.length - 3]; // Get the previous cursor
      setCursorHistory((prev) => prev.slice(0, -1)); // Remove the last cursor
      fetchOrders(prevCursor, currentPage - 1, selectedFilter);
    }
  };

  const saveToken = (userToken) => {
    localStorage.setItem('token', userToken);
    setToken(userToken);
  };

  const handleFilterChange = async (event) => {
    const newFilter = event.target.value;
    setSelectedFilter(newFilter);
    fetchOrders('', 1, newFilter);
  };



  const handleRowClick = async (orderId) => {
    setLoadingOrderId(orderId);
    try {
      setLoadingOrderId(null);
      const response = await fetch(`${API_BASE_URL}/fetchMetafields?orderId=${orderId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch metafields');
      }
      const data = await response.json();
      setMetafields(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoadingOrderId(null);
    }
  };

  const saveMetafield = async (metafields) => {
    try {
      const response = await fetch(`${API_BASE_URL}/saveMetafield`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
        body: JSON.stringify({ metafields }), // Send data as JSON in the request body
      });
      if (!response.ok) {
        throw new Error('Failed to save metafield');
      }
      const result = await response.json();
    } catch (error) {
      console.error('Error saving metafield:', error);
    }
  };


  const handleInputChange = (orderId, field, value) => {
    setRowData(prevData => ({
      ...prevData,
      [orderId]: { ...prevData[orderId], [field]: value }
    }));
  };

  const handleFileChange = async (event, orderId) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch('https://app.meroxio.com/shopify-app-apis/uploadImage', {
        method: 'POST',
        body: formData,
      });
      const result = await response.json();
      setUploadedImageUrl(prevUrls => ({ ...prevUrls, [orderId]: result.finalImageUrl }));
      handleInputChange(orderId, 'image', result.finalImageUrl);

    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const areAllFieldsFilled = (orderId) => {
    const fields = rowData[orderId];
    return fields && fields.image && fields.name && fields.location && fields.date;
  };


  const handleSave = async (orderId) => {
    const orderData = rowData[orderId];
    setSavingStatus(prev => ({ ...prev, [orderId]: 'saving' }));
    try {
      if (orderData) {
        const metafields = Object.entries(orderData).map(([key, value]) => ({
          orderId: orderId,
          key: key,
          value: value
        }));
        await saveMetafield(metafields);
        setSavingStatus(prev => ({ ...prev, [orderId]: 'saved' }));
      }
    } catch (error) {
      console.error('Error saving metafield:', error);
      setSavingStatus(prev => ({ ...prev, [orderId]: 'error' }));
    }
  };

  if (!token) {
    return <PasswordForm setToken={saveToken} />;
  }



  return (
    <div className="App">
      <header className="App-header">

        {loading && <div className="data-loader"><div><div></div><div></div><div></div><div></div></div></div>}
        {error && <p>Error: {error}</p>}
        {!loading && !error && (
          <div>
            <div className='brandPurpose'>
              <img src="https://www.drsheths.com/cdn/shop/files/width-1000px_200x.png" alt="Dr Sheths"></img>
              <h1>Brand Purpose</h1>
            </div>

            <button className='refreshButton' onClick={refreshPage}>Refresh</button>
            
            <select className='orderFilter' value={selectedFilter} onChange={handleFilterChange}>
              <option value={todayValue}>Today</option>
              <option value={yesterdayValue}>Yesterday</option>
              <option value={last7DaysValue}>Last 7 Days</option>
              <option value={last15DaysValue}>Last 15 Days</option>
            </select>

            {/* Conditionally render Bulk Edit button */}
            {selectedRows.length > 0 && (
              <button className="bulkEditBtn" onClick={handleOpenModal}>Bulk Edit</button>
            )}

            <Modal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              onSaveBulkEdit={onSaveBulkEdit}
              bulkEditData={bulkEditData}
              setBulkEditData={setBulkEditData}
              selectedOrders={selectedOrders}
            />

            <DataTable data={orders} handleFileChange={handleFileChange}
              metafields={metafields} uploadedImageUrl={uploadedImageUrl}
              handleInputChange={handleInputChange} handleSave={handleSave}
              currentPage={currentPage} savingStatus={savingStatus}
              setSavingStatus={setSavingStatus} areAllFieldsFilled={areAllFieldsFilled}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
            />
            <div className='paginationControls'>
              <button onClick={handlePreviousPage} disabled={currentPage <= 1}>Previous</button>
              <button onClick={handleNextPage} disabled={!cursorHistory[cursorHistory.length - 1]}>Next</button>
            </div>
          </div>
        )}
      </header>
    </div>
  );
}

export default App;
